// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyB5vFwie56xsAdifdrnuOPn3j-ZXSIJ7Y4',
    authDomain: 'comle-74ecb.firebaseapp.com',
    databaseURL: 'https://comle-74ecb.firebaseio.com',
    projectId: 'comle-74ecb',
    storageBucket: 'comle-74ecb.appspot.com',
    messagingSenderId: '950207085182',
    appId: '1:950207085182:web:26c1183f0df9a61fbb762f',
    measurementId: 'G-1P2705403R',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
