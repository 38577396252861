<header class="mat-typography">
  <mat-toolbar fxHide.lt-sm>
    <span class="example-spacer"></span>
    <a [routerLink]="['']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="mat-body-1">
      Home
    </a>
    <a [routerLink]="['/services']" routerLinkActive="active" class="mat-body-1">
      Services
    </a>
    <a [routerLink]="['/about']" routerLinkActive="active" class="mat-body-1">
      About
    </a>
    <a [routerLink]="['/testimonials']" routerLinkActive="active" class="mat-body-1">
      Testimonials
    </a>
    <a mat-button class="mat-body-1" href="tel:1-469-233-4765">
      <mat-icon>stay_primary_portrait</mat-icon>
    </a>
    <a mat-button class="mat-body-1" href="sms:1-469-233-4765">
      <mat-icon>textsms</mat-icon>
    </a>
    <a mat-button class="mat-body-1" href="mailto:langhausenenterprises@gmail.com" target="_blank"
      rel="noopener noreferrer">
      <mat-icon>email</mat-icon>
    </a>
  </mat-toolbar>
  <mat-toolbar fxHide.gt-sm>
    <span class="example-spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
        class="mat-body-1">
        Home
      </button>
      <button mat-menu-item [routerLink]="['/services']" routerLinkActive="active" class="mat-body-1">
        Services
      </button>
      <button mat-menu-item [routerLink]="['/about']" routerLinkActive="active" class="mat-body-1">
        About
      </button>
      <button mat-menu-item [routerLink]="['/testimonials']" routerLinkActive="active" class="mat-body-1">
        Testimonials
      </button>
      <a mat-menu-item class="mat-body-1" href="tel:1-469-233-4765">
        <mat-icon>stay_primary_portrait</mat-icon>
        <span> Contact</span>
      </a>
      <a mat-button class="mat-body-1 text-white w-100 text-left" href="sms:1-469-233-4765">
        <mat-icon>textsms</mat-icon>
        <span class="mat-body-1 ml-3"> SMS</span>
      </a>
      <a mat-menu-item class="mat-body-1" href="mailto:langhausenenterprises@gmail.com" target="_blank"
        rel="noopener noreferrer">
        <mat-icon>email</mat-icon>
        <span> Email</span>
      </a>
    </mat-menu>
  </mat-toolbar>
</header>
<main class="mat-typography h-100">
  <router-outlet></router-outlet>
</main>
