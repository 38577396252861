import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SHARED_COMPONENTS } from './components/shared.components';
import { MaterialsModule } from './materials.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [...SHARED_COMPONENTS],
  imports: [
    CommonModule,
    MaterialsModule,
    NgbModule,
    RouterModule,
    FlexLayoutModule,
  ],
  exports: [...SHARED_COMPONENTS, MaterialsModule, NgbModule, FlexLayoutModule],
})
export class SharedModule {}
